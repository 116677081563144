<template>
  <transition name="modal-fade">
    <div
      class="modal align-items-center"
      role="dialog"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="border-0 modal-header justify-content-center">
            <button type="button" class="btn-close" @click="close"></button>
          </div>

          <div class=" modal-body no-border">
            <div class="row">
              <div class="mb-4 text-center col-12 meetBox">
                <img
                  v-if="stutes === 'accept'"
                  src="@/assets/images/payment/ic_payment-success.svg"
                  class="mb-4 img-fluid tw-mx-auto md:tw-w-[110px] tw-w-[95.89px]"
                />
                <img
                  v-else
                  src="@/assets/images/payment/ic_payment-fail.svg"
                  class="mb-4 img-fluid tw-mx-auto md:tw-w-[110px] tw-w-[95.89px]" />
                <h3
                 v-if="stutes === 'accept'"
                  class=" tw-text-[#1e2022] tw-text-[24px] tw-font-bold tw-mb-[17px] tw-w-3/4 tw-mx-auto "
                >
                  {{ $t('contractsRenewal.contractRenewedCongratulations') }}
                </h3>
                <h3
                 v-else
                  class=" tw-text-[#1e2022] tw-text-[24px] tw-font-bold tw-mb-[17px] tw-w-3/4 tw-mx-auto ">
                 {{ $t('contractsRenewal.contractRejected') }}
                </h3>


                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "TalentAcceptRenewContractRequest",
  props: ["isOpen", "stutes"],
  watch: {
    isOpen: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      },
    },
  },
  methods: {
    close() {
      document.body.classList.remove("modal-open");
      this.$emit("update", false);
    },
  },
};
</script>


<style lang="scss" scoped>
.ltr {
  direction: ltr;
}

.modal-body.no-border {
      overflow: hidden;
}

.modal-open {
  overflow: hidden;
}

.modal-dialog {
    width: 794px;
}

.modal-body.no-border {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

@media (max-width: 700px) {
  .modal-dialog {
    width: 90%;
  }
}
</style>



