<template>
  <main class="contractsRenewal">
    <div class="container">
      <div class="contractsRenewal__wrapper">
        <h2 class="contractsRenewal__mainHeading">{{ $t('contractsRenewal.squadContractsRenewal') }}</h2>

        <div class="contractsRenewal__cardsWrapper">
          <template v-if="!isLoading && talentCards.length">
            <talent-card
              v-for="talent in talentCards"
              :key="talent.id"
              :talent-card="talent"
              :activeStatus="'talent-contract-renewal'"
              @renewTalentAccept="renewTalentAccept($event)"
              @renewTalentRejected="renewTalentRejected($event)"
            />
          </template>
          <div
            v-else-if="!isLoading && !talentCards.length"
            class="tw-text-center tw-mt-12 tw-mb-12"
          >
            <img
              src="@/assets/images/contract-renewal/no-contract.svg"
              alt="No Contracts"
              class="mx-auto tw-w-64"
            />
            <p class="tw-text-xl tw-font-bold tw-mt-8 tw-text-black">
              {{ $t("contractsRenewal.emptyList") }}
            </p>
          </div>
         <SkeletonLoaderTalentCard :count="1" v-else/>
        </div>
      </div>
    </div>
    <TalentRenewalContractRequestStutes :stutes="renewalStatus" v-if="isTalentRenewalContractRequestStutes" :isOpen="isTalentRenewalContractRequestStutes" @update="isTalentRenewalContractRequestStutes = $event" />

     <TalnetRejectRenewContractRequest
      v-if="isTalnetRejectRenewContractRequestOpen"
      :activeRejectContract="activeContract"
      :isOpen="isTalnetRejectRenewContractRequestOpen" @update="isTalnetRejectRenewContractRequestOpen = $event"
      @refresh="refresh"
    >
    </TalnetRejectRenewContractRequest>
     <TalentRenewContractRequest v-if="isTalentRenewContractRequestOpen"
      :isOpen="isTalentRenewContractRequestOpen"
      :activeContract="activeContract"
      @refresh="refreshAccept"
      @update="isTalentRenewContractRequestOpen = $event">
    </TalentRenewContractRequest>

  </main>
</template>

<script>
import TalentCard from '@/components/TalentsRenewalCard/index.vue';
import SkeletonLoaderTalentCard from '@/components/TalentsRenewalCard/SkeletonLoaderTalentCard.vue'
import TalentRenewalContractRequestStutes from "@/components/ContractRenewal/TalentRenewalContractRequestStutes.vue";
import TalnetRejectRenewContractRequest from "@/components/ContractRenewal/TalnetRejectRenewContractRequest.vue";
import TalentRenewContractRequest from "@/components/ContractRenewal/TalentRenewContractRequest.vue"

export default {
    components: {
        TalentCard,
        TalentRenewalContractRequestStutes,
         TalnetRejectRenewContractRequest,
        SkeletonLoaderTalentCard,
        TalentRenewContractRequest,
        
    },
  data() {
      return {
      isTalentRenewalContractRequestStutes: false,
      isTalnetRejectRenewContractRequestOpen: false,
      isTalentRenewContractRequestOpen: false,
      isLoading: false,
      talentCards: [],
      activeContract: null,
      renewalStatus: "rejected",
    };
    },
  methods: {
    refresh() { 
      this.activeContract = null;
      this.renewalStatus = "rejected"
      this.isTalentRenewalContractRequestStutes = true
      this.getContractsList();
    },
    refreshAccept() {
        this.activeContract = null;
        this.getContractsList()
        this.renewalStatus = "accept"
        this.isTalentRenewContractRequestOpen = false
        this.isTalentRenewalContractRequestStutes = true
    },
     renewTalentAccept(contract) {
      this.activeContract = contract;
      this.isTalentRenewContractRequestOpen = true;
      },
    renewTalentRejected(contract) {
      this.activeContract = contract;
      this.isTalnetRejectRenewContractRequestOpen = true;
    },
    async getContractsList() {
      this.isLoading = true;
      try {
        await this.axios
          .get(`/api/talent/contract-renewal`)
          .then((res) => {
            this.talentCards = res.data.data;
          });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    },
    mounted() {
         this.getContractsList();  
    },
  
};
</script>


<style lang="scss">
.contractsRenewal {
  &__wrapper {
    position: relative;
    z-index: 0;
    background-color: #fff;
    padding: 45px 20px;
    border-radius: 18px;
    margin: 60px auto;

    @media (min-width: 1200px) {
      width: 84%;
    }
    @media (min-width: 1400px) {
      width: 70%;
    }
  }

  &__mainHeading {
    font-size: 30px;

    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  &__navigation {
    display: flex;
    align-items: center;
    gap: 20px;
    overflow-x: scroll;
    margin-top: 24px;
    padding: 8px 65px;
    border-radius: 30px;
    box-shadow: 9px 7px 45px 0 rgba(66, 46, 135, 0.09);

    @media (max-width: 768px) {
      padding: 8px 15px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__navItem {
    border-radius: 30px;
    padding: 8px 9px;
    display: flex;
    align-items: center;
    color: #494d55;
    font-weight: 700;
    font-size: 16px;
    white-space: nowrap;
    min-width: 180px;
    justify-content: center;

    &.active {
      background-color: rgba(255, 198, 41, 1);
      color: #1e2022;
      font-weight: bold;
    }

    .loader {
      margin-inline-start: 7px;
    }
  }

  &__navItemCount {
    width: 34px;
    height: 34px;
    margin-inline-start: 7px;
    border-radius: 100%;
    background-color: rgba(241, 241, 241, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;

    &--visible {
      visibility: visible;
    }
  }

  &__multipleSelect {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 24px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 250px;
      padding: 16px;
      border: 1px solid rgba(241, 241, 241, 1);
      border-radius: 12px;
      transition: box-shadow 0.3s ease;

      svg {
        width: 21px;
        height: 21px;
      }

      span {
        font-size: 14px;
        font-weight: 600;
      }

      &:hover {
        box-shadow: 4px 2px 15px 0 rgba(66, 46, 135, 0.09);
      }
    }

    &.disabled {
      button {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  &__groupActions {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background-color: #fff;
    z-index: 10;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      padding-block: 20px;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    p {
      font-size: 16px;
      color: #000;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 160px;
      padding: 16px;
      border-radius: 12px;
      color: #fff;
      transition: box-shadow 0.3s ease;

      img {
        width: 21px;
        height: 21px;
      }

      span {
        font-size: 14px;
        font-weight: 600;
      }

      &:hover {
        box-shadow: 4px 2px 15px 0 rgba(66, 46, 135, 0.09);
      }
    }
  }

  &__groupActionsCTAs {
    display: flex;
    gap: 16px;

    @media (max-width: 768px) {
      width: 100%;
      justify-content: space-between;
    }

    button:first-child {
      background-color: #26daa6;
    }
    button:last-child {
      background-color: #ff6f59;
    }
  }
}
</style>