<template>
  <transition name="modal-fade">
    <div
      class="modal align-items-center"
      role="dialog"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-scrollable d-flex justify-content-center align-items-center">
        <div class="modal-content">
          <div class="border-0 modal-header removeTitleBorder">
            <div class="w-100">
              <h4 class="p-0 modal-title main-color md:tw-text-center bold md:tw-text-[30px] tw-text-[25px]">{{ $t('contractsRenewal.rejectionReason') }}</h4>
              <hr class="smallHr" />
            </div>
            
          </div>
          <div class="pt-0 border-0 modal-body">
            
            <div class=" tw-mb-4">
            <Select
            :label="$t('contractsRenewal.selectReason')"
            :validationField="$v.reason"
            :selectOptions="selectOptions"
          />
            </div>

             <div>
                <Textarea
                :validationField="$v.comment"
                :placeholder="$t('contractsRenewal.WriteReason')"
                :label="$t('contractsRenewal.WriteReason')"
                rows="8"
                :params="[{ maxLength: { number: '5000' } }, 'required' ]"
                />
            </div>
           
          </div>
          <div class="pt-0 border-0 modal-footer">
            <div class=" d-flex tw-justify-center tw-w-full">
              <button
              type="button"
              class="btn cancelbtn btnstyleUpdate  tw-h-[50px] !tw-flex tw-justify-center tw-items-center tw-min-w-[162.75px] tw-rounded-xl tw-border-2 tw-border-solid tw-border-[#c5c5c5] "
              data-bs-dismiss="modal"
              @click="close"
            >
              {{ $t("talentWizard.cancel") }}
            </button>
            <button @click="handleTalentRejectRenewContractRequest" :disabled="$v.$invalid" type="submit" class="btn btn-primary saveBtn tw-h-[50px] !tw-flex tw-justify-center tw-items-center tw-min-w-[162.75px] ">
              {{ $t("contractsRenewal.confirm") }}
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import toast from "../../services/toast.js";
import { maxLength, required, requiredIf } from "vuelidate/lib/validators";
import Select from "@/components/shared/formControls/select/select.vue";
import Textarea from "@/components/shared/formControls/textarea/textarea.vue";

export default {

  name: "TalentRejectRenewContractRequest",
  components: {Textarea, Select},
    props: ["isOpen", "activeRejectContract"],
  data() {
      return {
        isLoading: false,
        selectOptions: [],
        reason: null,
        comment: null,
    }
    },
  mounted () {
    this.getContractRejectionReason();
  },
  watch: {
    reason: {
      immediate: true,
      deep: true,
      handler() {
          this.$v.$touch();
      },
    },
    isOpen: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      },
    },
    },
    validations: {
        reason: {
            required
    },
    comment: {
      maxLength: maxLength(5000),
       required: requiredIf(function () {
              return this.reason === 0;
      }),
    },
  },
    methods: {
        resetForm() {
            this.reason = null;
            this.comment = null;
      },
   async getContractRejectionReason() {
        this.isLoading = true;
      try {
        await this.axios
          .get(`/api/staticContent/contractRejectionReason?type=1`)
          .then((res) => {
            this.selectOptions = res.data.data
          });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
        },
    async handleTalentRejectRenewContractRequest() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
        try {
         let contract = this.activeRejectContract
      
        await this.axios
          .post(`/api/talent/contract-renewal/${contract.id}/reject`, {
            reason: this.reason,
            comment: this.comment,
          })
          .then(() => {
            this.$emit("refresh");
            this.close()
          });
      } catch (error) {
        console.error(error);
        toast.error(error.response.data?.message || error.message);
      }
    },
    close() {
        this.resetForm()
      document.body.classList.remove("modal-open");
      this.$emit("update", false);
    },
  },
};
</script>

<style scoped>
.modal-dialog-scrollable .modal-content {
    max-height: 90%;
    overflow: auto;
}
</style>

<style lang="scss" scoped>
.ltr {
  direction: ltr;
}

.modal-body.no-border {
      overflow: hidden;
}

.modal-open {
  overflow: hidden;
}

.modal-dialog {
  width: 70%;
}

.modal-body.no-border {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

@media (max-width: 700px) {
     
  .modal-dialog {
    width: 90%;
  }
}
</style>

