<template>
  <transition name="modal-fade">
    <div
      class="modal align-items-center"
      role="dialog"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="border-0 modal-header justify-content-center">
            <button type="button" class="btn-close" @click="close"></button>
          </div>

          <div class=" modal-body no-border !tw-p-4">
            <div class="row">
              <div class="mb-4 text-center col-12 meetBox">
                <img src='@/assets/images/contract-renewal/ic_confirm-file.svg' class="tw-mb-8 img-fluid tw-mx-auto md:tw-w-[96px] tw-w-[96px]" />
               
                <h3
                  class=" tw-text-[#1e2022] tw-text-[20px] md:tw-text-[24px] tw-font-bold tw-mb-[31px] tw-w-4/5 tw-mx-auto "
                >

                   {{ $t("contractsRenewal.areYouSureWantToAcceptTheContractRenewal") }}

                </h3>

                <div class=" d-flex tw-flex-col-reverse md:tw-flex-row tw-justify-center tw-w-full">
                <button
                type="button"
                class="btn cancelbtn btnstyleUpdate  tw-h-[50px] tw-mx-1 !tw-flex  tw-w-full  md:tw-w-2/5 tw-justify-center tw-items-center tw-min-w-[162.75px] tw-rounded-xl !tw-border-2  !tw-border-solid !tw-border-[#c5c5c5] hover:!tw-border-2  hover:!tw-border-solid hover:!tw-border-[#c5c5c5] "
                data-bs-dismiss="modal"
                @click="close"
                :disabled="isDisabled" 
                >
                     {{ $t("contractsRenewal.no") }}
                </button>
                <button :disabled="isDisabled"  @click="renewTalentAccept" type="submit" class="btn btn-primary saveBtn tw-mx-1 tw-w-full tw-mb-4 md:tw-mb-0  md:tw-w-2/5 tw-h-[50px] !tw-flex tw-justify-center tw-items-center tw-min-w-[162.75px] ">
                     {{ $t("contractsRenewal.yes") }}
                </button>
                </div>

                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import toast from "../../services/toast.js";

export default {
  name: "TalentRenewContractRequest",
    props: ["isOpen", "activeContract"],
  data() {
    return {
        isDisabled: false
    }
  },
  watch: {
    isOpen: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      },
    },
  },
    methods: {
    async renewTalentAccept() {
            try {
        this.isDisabled = true;
        await this.axios
          .post(`/api/talent/contract-renewal/${this.activeContract.id}/accept`)
            .then(() => {
                this.$emit("refresh");
            
            }).catch((error) => {
            console.error(error);
          }).finally(() => {
            this.isDisabled = false;
          });
      } catch (error) {
        console.error(error);
        toast.error(error.response.data?.message || error.message);
      }  
    },
    close() {
      document.body.classList.remove("modal-open");
      this.$emit("update", false);
    },
  },
};
</script>


<style lang="scss" scoped>
.ltr {
  direction: ltr;
}

.modal-body.no-border {
      overflow: hidden;
}

.modal-open {
  overflow: hidden;
}

.modal-dialog {
    width: 725px;
}

.modal-body.no-border {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

@media (max-width: 700px) {
  .modal-dialog {
    width: 90%;
  }
}
</style>



